import React, { useState, useEffect, useContext } from "react";
// import { useParams } from "react-router-dom";
// import handleViewport from "react-in-viewport";
import styles from "./Todos.module.scss";
import _ from "lodash";
import { TableRow, TableCell, MenuItem, Tooltip, Typography, Checkbox } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import Icon from "@mdi/react";
import {
  mdiRhombus,
  mdiRhombusSplit,
  mdiPoll,
  mdiBullseyeArrow,
  mdiLinkVariantOff,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiCalendarAlert,
  mdiChartTimelineVariant,
} from "@mdi/js";
import { LooksOne, LooksTwo, Looks3 } from "@material-ui/icons";
// import useInViewport from "../../hooks/useInViewport";
import Menu from "../Menu/Menu";
import Status from "../Status/Status";
import UserAvatars from "../UserAvatars/UserAvatars";
import NotesButton from "../Notes/NotesButton";
import { getTodoTieIn } from "../../utils/graphql";
import { formatAs, diffInDays } from "../../utils/dates";
import PlanPill from "../PlanPill/PlanPill";
import { withStyles } from "@material-ui/styles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const Todo = ({
  // inViewport,
  forwardedRef,
  category,
  showComplete,
  filterUsers,
  showAccountable,
  handleAddDialog,
  handleCheckbox,
  checked,
  canEdit,
  todo,
  hideNoteBtn = false,
  handleEditDialog,
  planId,
  handleConfirmOpen,
  handleUpdatePriority,
  handleUpdateStatus,
  handleToggleCategory,
  isNotesDialog = false,
}) => {
  const { id, value, done, referenceId, referenceModel, createdAt, user, priority, plan } = todo;

  const [tieIn, setTieIn] = useState();
  // const hasRendered = useInViewport(inViewport);

  useEffect(() => {
    if (referenceModel) {
      (async () => {
        const model = referenceModel === "Issue" ? "Todo" : referenceModel;

        const tieIn = await getTodoTieIn(referenceId, model);
        const value = _.get(tieIn, `data.${_.camelCase(model)}.value`);

        setTieIn(value);
      })();
    }
  }, []);

  // if (!hasRendered)
  //   return (
  //     <TableRow ref={forwardedRef} className={styles.skeletonRow}>
  //       <Skeleton long={filterUsers} />
  //     </TableRow>
  //   );

  const daysAgo = Math.abs(diffInDays(createdAt, new Date()));
  const overdue = daysAgo > 14 && !done;
  const checkedIndex = checked ? _.indexOf(checked, id) : null;

  return (
    <TableRow ref={forwardedRef}>
      {(showAccountable || filterUsers) && (
        <TableCell className={isNotesDialog ? undefined : styles.firstCell}>
          <UserAvatars users={user ? [user] : []} />
        </TableCell>
      )}
      <TableCell
        className={
          showAccountable || filterUsers ? (hideNoteBtn ? undefined : styles.secondCell) : isNotesDialog ? undefined : styles.firstCell
        }
      >
        <div className={styles.flex}>
          {handleCheckbox && (
            <Checkbox onChange={handleCheckbox(id)} checked={checked.includes(id)} checkedIcon={iconPaths[checkedIndex]} />
          )}
          {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
          <LightTooltip title={value} interactive placement="left">
            <div className={done ? styles.completed : undefined} style={{ maxHeight: "35px", overflow: "hidden" }}>
              {value}
            </div>
          </LightTooltip>
          {!hideNoteBtn && (
            <NotesButton
              id={id}
              model={category}
              doc={todo}
              tabs={["notes", "issues", "todos"]}
              canEditTodo={canEdit}
              value={value.trim()}
              user={user ? user.id : null}
              planId={planId}
            />
          )}
          {canEdit && (
            <Menu>
              {/* {category === "issue" && <MenuItem onClick={handleAddDialog("todo", id, "Issue")}>Add Todo</MenuItem>} */}
              <MenuItem onClick={handleEditDialog(true, todo)}>Edit</MenuItem>
              <MenuItem onClick={handleConfirmOpen(true, todo)} className={styles.delete}>
                Delete
              </MenuItem>
              <MenuItem onClick={handleToggleCategory(todo)}>Change to {category === "issue" ? "To Do" : "Issue"}</MenuItem>
             {/*  {category === "todo" && <MenuItem onClick={handleAddDialog('issue',id,'Todo',`To Do Issue regarding: ${value}`, user ? user.id : null)}>Create Tied In Issue</MenuItem>} */}
            </Menu>
          )}
        </div>
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            referenceModel ? (
              <Typography variant="body2">
                <span className={styles.label}>
                  {_.startCase(referenceModel)}: <br />
                </span>
                {tieIn}
              </Typography>
            ) : (
              <Typography variant="body2">No tie in</Typography>
            )
          }
        >
          <Icon path={iconPaths[referenceModel] || mdiLinkVariantOff} size={1.25} color="rgba(0, 0, 0, 0.54)" />
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <div className={styles.flexCenter}>
          <Status priority={priority} />
          {canEdit && (
            <Menu icon="arrow">
              <MenuItem onClick={handleUpdatePriority("high", todo)} disabled={priority === "high"}>
                Mark as on high priority
              </MenuItem>
              <MenuItem onClick={handleUpdatePriority("medium", todo)} disabled={priority === "medium"}>
                Mark as on medium priority
              </MenuItem>
              <MenuItem onClick={handleUpdatePriority("low", todo)} disabled={priority === "low"}>
                Mark as on low priority
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <Status done={done} />
          {canEdit && (
            <Menu icon="arrow">
              <MenuItem onClick={handleUpdateStatus(todo)} disabled={done}>
                Mark as complete
              </MenuItem>
              <MenuItem onClick={handleUpdateStatus(todo)} disabled={!done}>
                Mark as incomplete
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      <TableCell align="center">
        <div className={styles.flexRelative}>
          {overdue && <Icon path={mdiCalendarAlert} size={1} color={red[400]} className={styles.iconOverdue} />}
          <Typography noWrap variant="inherit">
            {formatAs(createdAt, "MMM d")} <br />({daysAgo} {daysAgo === 1 ? "day" : "days"} ago)
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Todo;

const iconPaths = {
  SuccessCriteria: mdiRhombusSplit,
  Rock: mdiRhombus,
  Metric: mdiPoll,
  Objective: mdiChartTimelineVariant,
  Issue: mdiAlertDecagram,
  Todo: mdiCheckboxMarked,
  WeeklyTarget: mdiBullseyeArrow,
  0: <LooksOne />,
  1: <LooksTwo />,
  2: <Looks3 />,
};
