import React, { useEffect, useContext, useState } from "react";
import styles from "./Notes.module.scss";
import useNotes from "./useNotes";
import _ from "lodash";
import { isAuthed } from "../../utils/authorization";
import { NotesContext } from "../../context/notesContext";
import Loading from "../Loading/Loading";
import DeleteConfirm from "./DeleteConfirm";
import TodosComponent from "../../components/Todos/Todos";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Select,
  MenuItem,
  Table,
  Tabs,
  Tab,
  Badge,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDownload, mdiFileDocumentOutline, mdiFileImage } from "@mdi/js";

import { fullDate } from "../../utils/dates";
import { deserialize } from "../RichText/functions";
import RichText from "../RichText/RichText";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useTableFilters from "../../hooks/useTableFilters";
import { TODO_FIELDS } from "../../utils/fragments";

// localstorage location
const NOTES_TODO_LOCATION = "notesTodo";
const NOTES_ISSUE_LOCATION = "notesIssue";

const Notes = ({ user, snack, setDialog, dialog, fetch }) => {
  const [currentTab, setCurrentTab] = useState("notes");

  const {
    data: { id, model, core, openInEditMode, user: userId, value: referenceObjectValue, additionalProps, planId },
    notes,
    setRefetchId,
    tabs,
    disableEditTodo,
    onCreateNote,
  } = useContext(NotesContext);

  const {
    data,
    editMode,
    getData,
    handleEditMode,
    handleChange,
    handleClose,
    handleExitDialog,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleRemoveImage,
    handleCopyImageToClipboard,
  } = useNotes({
    user,
    snack,
    notes,
    model,
    setRefetchId,
    openInEditMode,
    onCreateNote,
  });

  // todo
  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  } = useTableFilters({
    location: NOTES_TODO_LOCATION,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${NOTES_TODO_LOCATION}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${NOTES_TODO_LOCATION}.showCompleted`)) || true,
      sortValue: JSON.parse(localStorage.getItem(`${NOTES_TODO_LOCATION}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${NOTES_TODO_LOCATION}.sortOrder`)) || "asc",
    },
  });

  // issue
  const {
    page: page2,
    rowsPerPage: rowsPerPage2,
    sort: sort2,
    searchTerm: searchTerm2,
    debouncedSearchTerm: debouncedSearchTerm2,
    showCompleted: showCompleted2,
    handleChangePage: handleChangePage2,
    handleRowsPerPage: handleRowsPerPage2,
    setSort: setSort2,
    setSearchTerm: setSearchTerm2,
    setShowCompleted: setShowCompleted2,
  } = useTableFilters({
    location: NOTES_ISSUE_LOCATION,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${NOTES_ISSUE_LOCATION}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${NOTES_ISSUE_LOCATION}.showCompleted`)) || true,
      sortValue: JSON.parse(localStorage.getItem(`${NOTES_ISSUE_LOCATION}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${NOTES_ISSUE_LOCATION}.sortOrder`)) || "asc",
    },
  });

  const [getRelatedTodos, { data: todoData, loading }] = useLazyQuery(GET_RELATED_TODOS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getData(id, model, core, additionalProps);
    if (openInEditMode) {
      handleEditMode(true)();
    }
    if (!_.isNil(id) && !_.isNil(model)) {
      getRelatedTodos({
        variables: {
          referenceId: id,
          referenceModel: _.upperFirst(model),
          // todos
          page,
          rowsPerPage,
          searchTerm: debouncedSearchTerm,
          sortBy: sort.value,
          sortDir: sort.order,
          done: showCompleted === true ? null : false,
          //issues
          page2,
          rowsPerPage2,
          searchTerm2: debouncedSearchTerm2,
          sortBy2: sort2.value,
          sortDir2: sort2.order,
          done2: showCompleted2 === true ? null : false,
        },
      });
    }
    if (!tabs.includes(currentTab)) {
      setCurrentTab("notes");
    }
  }, [id, fetch]);

  const handleAddTodoOrIssue =
    (category, referenceModel = null, referenceId = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: { open: true, category, referenceId, referenceModel, value, user, planId },
      });
    };

  const todosTotal = _.get(todoData, "todosCount.total", 0);
  const issuesTotal = _.get(todoData, "issuesCount.total", 0);
  const todosTotalCompleted = _.get(todoData, "todosCount.totalCompleted", 0);
  const issuesTotalCompleted = _.get(todoData, "issuesCount.totalCompleted", 0);
  const numOfNotes = (data || []).length;
  return (
    <Dialog
      open={Boolean(id)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="md"
      fullWidth
      TransitionProps={{
        onExited: handleExitDialog,
      }}
    >
      <DialogTitle className={styles.title} disableTypography>
        {tabs && tabs.length > 1 ? (
          // <div className={styles.filter}>
          //   <Select
          //     value={currentTab}
          //     onChange={(e) => {
          //       setCurrentTab(e.target.value);
          //     }}
          //   >
          //     {tabs.map((tab) => (
          //       <MenuItem key={tab} value={tab}>
          //         {tab.toUpperCase()}
          //       </MenuItem>
          //     ))}
          //   </Select>
          // </div>

          <Tabs
            onChange={(e, value) => setCurrentTab(value)}
            value={currentTab}
            centered
            indicatorColor="primary"
            textColor="primary"
            style={{ flexGrow: 1 }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab}
                label={
                  <Badge
                    badgeContent={tab === "issues" ? issuesTotal : tab === "todos" ? todosTotal : numOfNotes}
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{ fontSize: "11px" }}
                  >
                    <span style={{ fontSize: "14px" }}>{tab === "todos" ? "To Dos" : tab}</span>
                  </Badge>
                }
                value={tab}
              />
            ))}
          </Tabs>
        ) : (
          <Typography variant="h5">{_.startCase(model)} Notes</Typography>
        )}
        {isAuthed(user.user, "department facilitator") && (
          <>
            {currentTab === "notes" ? (
              <>
                {editMode.open ? (
                  <Button color="primary" variant="outlined" onClick={handleEditMode(false)} className={styles.mainButton}>
                    Cancel
                  </Button>
                ) : (
                  <Button color="primary" variant="contained" onClick={handleEditMode(true)} className={styles.mainButton}>
                    New {currentTab && currentTab.slice(0, currentTab.length - 1)}
                  </Button>
                )}
              </>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddTodoOrIssue(
                  currentTab === "todos" ? "todo" : "issue",
                  _.upperFirst(model),
                  id,
                  referenceObjectValue,
                  userId
                )}
                disabled={disableEditTodo}
                className={styles.mainButton}
              >
                New {currentTab === "todos" ? "to do" : currentTab && currentTab.slice(0, currentTab.length - 1)}
              </Button>
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent className={styles.content}>
        {currentTab === "notes" && (
          <NotesContent
            data={data}
            editMode={editMode}
            handleChange={handleChange}
            core={core}
            model={model}
            user={user}
            id={id}
            handleDelete={handleDelete}
            additionalProps={additionalProps}
            handleEditMode={handleEditMode}
            handleRemoveImage={handleRemoveImage}
            handleCopyImage={handleCopyImageToClipboard}
          />
        )}
        {currentTab === "todos" && (
          <TodoContent
            loading={loading}
            todos={_.get(todoData, "todos", [])}
            model={model}
            type={"todos"}
            canEdit={isAuthed(user.user, "department facilitator")}
            handleChangePage={handleChangePage}
            handleRowsPerPage={handleRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            searchTerm={searchTerm}
            setSort={setSort}
            setSearchTerm={setSearchTerm}
            setShowCompleted={setShowCompleted}
            showCompleted={showCompleted}
            location={NOTES_TODO_LOCATION}
            total={todosTotal}
            totalCompleted={todosTotalCompleted}
            planId={additionalProps.planId}
          />
        )}
        {currentTab === "issues" && (
          <TodoContent
            loading={loading}
            todos={_.get(todoData, "issues", [])}
            model={model}
            type={"issues"}
            canEdit={isAuthed(user.user, "department facilitator")}
            handleChangePage={handleChangePage2}
            handleRowsPerPage={handleRowsPerPage2}
            page={page2}
            rowsPerPage={rowsPerPage2}
            sort={sort2}
            searchTerm={searchTerm2}
            setSort={setSort2}
            setSearchTerm={setSearchTerm2}
            setShowCompleted={setShowCompleted2}
            showCompleted={showCompleted2}
            location={NOTES_ISSUE_LOCATION}
            total={issuesTotal}
            totalCompleted={issuesTotalCompleted}
            planId={additionalProps.planId}
          />
        )}
      </DialogContent>
      <DialogActions>
        {editMode.open && _.isNil(editMode.id) && (
          <Button variant="contained" onClick={handleCreate(id, model, core, additionalProps)} color="primary">
            Create
          </Button>
        )}
        {editMode.open && !_.isNil(editMode.id) && (
          <Button variant="contained" onClick={handleUpdate({ id, model, core, additionalProps })} color="primary">
            Update
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notes;

const NotesContent = ({
  data,
  editMode,
  handleChange,
  core,
  model,
  user,
  id,
  handleDelete,
  handleEditMode,
  handleRemoveImage,
  handleCopyImage,
  additionalProps,
}) => {
  return (
    <>
      {!data && <Loading />}
      {editMode.open ? (
        <>
          <RichText value={editMode.text} readOnly={false} onChange={(value) => handleChange({ target: { name: "text", value } })} />
          <input
            name="file"
            onChange={handleChange}
            accept="image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
            id="file-upload"
            type="file"
            className={styles.input}
          />
          <label htmlFor="file-upload">
            <Button color="primary" variant="contained" component="span" className={styles.uploadbtn}>
              Upload File
            </Button>
          </label>
          <Typography variant="subtitle2" display="inline" className={styles.filename}>
            {_.get(editMode, "file.name", _.get(editMode, "filename"))}
          </Typography>
        </>
      ) : (
        <>
          {_.isEmpty(data) && <DialogContentText>No {_.lowerCase(core || model)} notes</DialogContentText>}
          <List>
            {data &&
              data.map((note) => {
                const { id: noteId, user: noteUser, date, text, url, filename, type } = note;
                const isImg = type === "img";
                const path = isImg ? mdiFileImage : mdiFileDocumentOutline;

                return (
                  <ListItem key={date} dense>
                    <ListItemText>
                      <div className={styles.listItemText}>
                        <div className={styles.flex}>
                          <Avatar className={styles.avatar}>
                            <Icon path={path} color="#fff" size={1} />
                          </Avatar>
                          <div>
                            <Typography variant="subtitle2" style={{ display: "flex", alignItems: "center" }}>
                              {!_.isNil(noteUser) && `${_.get(noteUser, "name.first")} ${_.get(noteUser, "name.last")}`}
                              {url !== "undefined" && !_.isNil(url) && (
                                <a
                                  href={url}
                                  target="__blank"
                                  download
                                  className={styles.downloadLink}
                                  style={{ marginLeft: _.isNil(noteUser) ? 0 : 20 }}
                                >
                                  <Icon path={mdiDownload} color="#3f51b5" size={0.75} />
                                  {filename}
                                </a>
                              )}
                            </Typography>
                            <Typography variant="caption">{fullDate(date)}</Typography>
                          </div>
                        </div>
                        {isAuthed(user.user, "department facilitator") && (
                          <div>
                            <DeleteConfirm
                              id={noteId}
                              referenceId={id}
                              model={model}
                              core={core}
                              filename={filename}
                              handleDelete={handleDelete}
                              additionalProps={additionalProps}
                              handleEditMode={handleEditMode}
                              handleRemoveImage={handleRemoveImage}
                              handleCopyImage={isImg ? handleCopyImage : null}
                              note={note}
                            />
                          </div>
                        )}
                      </div>
                      <RichText value={deserialize(text)} readOnly={true} />
                      {isImg && <img src={url} alt="" className={styles.image} />}
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </>
      )}
    </>
  );
};

const TodoContent = ({
  loading,
  todos,
  model,
  type,
  canEdit,
  handleChangePage,
  handleRowsPerPage,
  page,
  rowsPerPage,
  sort,
  searchTerm,
  setSort,
  setSearchTerm,
  setShowCompleted,
  showCompleted,
  location,
  total,
  totalCompleted,
  planId,
}) => {
  return (
    <>
      {loading && <Loading />}
      {_.isEmpty(todos) ? (
        <DialogContentText>
          No {_.lowerCase(model)} {type === "issues" ? "issues" : "todos"}
        </DialogContentText>
      ) : (
        <TodosComponent
          todos={todos}
          issues={type === "issues" ? true : false}
          showAccountable
          hideHeader={true}
          hideNoteBtn={true}
          handleChangePage={handleChangePage}
          handleRowsPerPage={handleRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          sort={sort}
          searchTerm={searchTerm}
          setSort={setSort}
          setSearchTerm={setSearchTerm}
          setShowCompleted={setShowCompleted}
          showCompleted={showCompleted}
          location={location}
          total={total}
          totalCompleted={totalCompleted}
          planId={planId}
          isNotesDialog={true}
        />
      )}
    </>
  );
};

const GET_RELATED_TODOS = gql`
  query Notes_GetRelatedTodos(
    $referenceId: ID!
    $referenceModel: String!
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean
    $page2: Int
    $rowsPerPage2: Int
    $searchTerm2: String
    $sortBy2: String
    $sortDir2: String
    $done2: Boolean
  ) {
    todos(
      referenceId: $referenceId
      referenceModel: $referenceModel
      category: "todo"
      page: $page
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      done: $done
    ) {
      ...TodoFields
    }
    todosCount: todosCount(referenceId: $referenceId, referenceModel: $referenceModel, category: "todo", searchTerm: $searchTerm) {
      total
      totalCompleted
    }
    issues: todos(
      referenceId: $referenceId
      referenceModel: $referenceModel
      category: "issue"
      page: $page2
      rowsPerPage: $rowsPerPage2
      searchTerm: $searchTerm2
      sortBy: $sortBy2
      sortDir: $sortDir2
      done: $done2
    ) {
      ...TodoFields
    }
    issuesCount: todosCount(referenceId: $referenceId, referenceModel: $referenceModel, category: "issue", searchTerm: $searchTerm) {
      total
      totalCompleted
    }
  }

  ${TODO_FIELDS}
`;
